<!-- Hero -->
<div class="container">
  <div class="row pt-xl">
    <div class="col col-12 col-lg-6">
      <h1 class="pb-sm headline-1">
        Maklermandat.ch<br>Stabile und planbarere<br>
        Einkommensquelle
      </h1>
    </div>
    <div class="col col-12 col-lg-6 headline-3 pt-lg-3 pb-sm">
      Mit einem Maklermandat können Sie als Versicherungsvermittler Ihre Kunden langfristig und nachhaltig
      betreuen und sichern sich wiederkehrende Provisionen bzw. Courtagen auf sämtliche Versicherungsverträge im
      Portfolio Ihrer Kundschaft, ohne ständig neuen Verkaufsdruck zu verspüren.
      Dank der Webapplikation von Maklermandat.ch wird dieser Prozess für Sie ganz einfach digital erledigt!
    </div>
  </div>
</div>

<!-- Component -->
<div class="container">
  <div class="tab-section pt-5 pb-xl d-none d-lg-block">
    <div class="row">
      <div class="col">
        <!-- Hauptbildcontainer -->
        <div class="ratio ratio-16x9">
          <img src="{{activeImage}}" alt="Active Image"/>
        </div>
      </div>
    </div>
    <div class="row pt-5">
      <!-- Tab -->
      @for (item of contentData; track item; let idx = $index) {
        <a class="col tab"
           (click)="setActiveImage(item.image, idx)">
          <div class="tab-indicator-container"
               [ngClass]="{'active': activeIndex === idx}">
            <div class="tab-indicator-bar"></div>
          </div>
          <h3 class="pt-sm headline-2" [innerHTML]="item.title"></h3>
          <p class="pt-2">{{ item.description }}</p>
        </a>
      }
    </div>
  </div>
</div>


<!-- Swiper -->
<div class="d-block d-lg-none pt-xl pb-xl">
  <div appFadeIn class="swiper">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      @for (item of contentData; track item; let first = $first) {
        <div class="swiper-slide">
          <div class="swiper-img" style="position: relative">
            <img src="{{item.image}}" alt="Active Image"/>
          </div>
          <div>
            <div class="swiper-text pt-4">
              <h3 class="pt-sm headline-2" [innerHTML]="item.title"></h3>
              <p class="pt-2">{{ item.description }}</p>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>


<div appFadeIn class="container">
  <section class="headline-cta-section pt-xl pb-xl text-lg-center">
    <header>
      <h2 class="title headline-1 ">Nutzen Sie Maklermandat.ch.</h2>
      <p class="headline-1">Bauen Sie Ihr Kundenportfolio digital auf und <br class="d-lg-block d-none">sichern Sie sich
        passives Einkommen.</p>
    </header>
    <div appAnimation class="cta pt-4">
      <button mat-stroked-button
              color="primary"
              class="button-rounded"
              (click)="openDialog()">
        Beratungstermin vereinbaren
        <span class="animated-button-symbol">
          <mat-icon class="material-symbols-outlined">add</mat-icon>
        </span>
      </button>
    </div>
  </section>
</div>


<!-- Accordion -->
<div appFadeIn class="container">
  <div class="pt-xl text-lg-center d-block d-lg-none">
    <div class="headline-1">Vorteile des Maklermandats</div>
  </div>
  <div class="accordion-section pt-xl pb-xl">
    <div class="row">
      <div class="col col-12 col-lg-6">
        <div class="ratio ratio-3x4">
          <img src="./assets/images/images/placeholder.jpg" alt="Benefits">
        </div>
      </div>
      <div class="col d-flex align-items-center justify-content-center">
        <div class="content-container">
          <div class="overline py-4 d-none d-lg-block">Vorteile des Maklermandats</div>
          <mat-accordion class="example-headers-align">
            @for (item of contentBenefitsData; track item) {
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h3 class="headline-2">{{ item.title }}</h3>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-content">
                  {{ item.description }}
                </div>
              </mat-expansion-panel>
            }
          </mat-accordion>
          <div appAnimation class="cta pt-5">
            <button mat-stroked-button
                    color="primary"
                    class="button-rounded"
                    (click)="openDialog()">
              mehr erfahren
              <span class="animated-button-symbol">
                <mat-icon class="material-symbols-outlined">add</mat-icon>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div appFadeIn class="container">
  <div class="pt-xl text-lg-center">
    <p class="headline-1">So funktioniert maklermandat.ch:<br class="d-none d-lg-block"> einfach, digital und effizient.
    </p>
  </div>
</div>

<!-- Component -->
<div appFadeIn class="container">
  <div class="tab-section pt-xl pb-xl d-none d-lg-block">
    <div class="row">
      <div class="col">
        <!-- Hauptbildcontainer -->
        <div class="ratio ratio-16x9">
          <img src="{{activeImage}}" alt="Active Image"/>
        </div>
      </div>
    </div>
    <div class="row pt-md">
      <!-- Tab -->
      @for (item of contentFunctionData; track item; let idx = $index) {
        <a class="col tab"
           (click)="setActiveImage(item.image, idx)">
          <div class="tab-indicator-container"
               [ngClass]="{'active': activeIndex === idx}">
            <div class="tab-indicator-bar"></div>
          </div>
          <h3 class="pt-sm headline-2" [innerHTML]="item.title"></h3>
          <p class="pt-2">{{ item.description }}</p>
        </a>
      }
    </div>
  </div>
</div>

<!-- Swiper -->
<div class="d-block d-lg-none pt-xl pb-xl">
  <div appFadeIn class="swiper">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      @for (item of contentFunctionData; track item; let first = $first) {
        <div class="swiper-slide">
          <div class="swiper-img" style="position: relative">
            <img src="{{item.image}}" alt="Active Image"/>
          </div>
          <div>
            <div class="swiper-text pt-4">
              <h3 class="pt-sm headline-2" [innerHTML]="item.title"></h3>
              <p class="pt-2">{{ item.description }}</p>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>

<!-- Accordion -->
<div appFadeIn class="container">
  <div class="pt-xl text-lg-center d-block d-lg-none">
    <div class="headline-1">Vier Vorteile als Broker auf maklermandat.ch</div>
  </div>
  <div class="accordion-section pt-xl pb-xl">
    <div class="row">
      <!-- Spalte für das Bild -->
      <div class="col-12 col-lg-6 order-1 order-lg-2">
        <div class="ratio ratio-3x4">
          <img src="./assets/images/images/placeholder.jpg" alt="Benefits">
        </div>
      </div>

      <!-- Spalte für den Inhalt -->
      <div class="col-12 col-lg-6 order-2 order-lg-1 d-flex align-items-center justify-content-center">
        <div class="content-container">
          <div class="overline py-4 d-none d-lg-block">Vier Vorteile als Broker auf maklermandat.ch</div>
          <mat-accordion class="example-headers-align">
            @for (item of contentBenefitsMandatData; track item) {
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h3 class="headline-2">{{ item.title }}</h3>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-content">
                  {{ item.description }}
                </div>
              </mat-expansion-panel>
            }
          </mat-accordion>
          <div appAnimation class="cta pt-5">
            <button mat-stroked-button
                    color="primary"
                    class="button-rounded"
                    (click)="openDialog()">
              mehr erfahren
              <span class="animated-button-symbol">
                <mat-icon class="material-symbols-outlined">add</mat-icon>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div appFadeIn class="container">
  <div class="row pt-xl pb-xl">
    <div class="col col-12 col-lg-6 pb-sm">
      <h2 class="headline-1">Bei weiteren Fragen helfen wir gerne weiter.</h2>
    </div>
    <div class="col col-12 col-lg-6">
      Kontaktieren Sie unseren per Telefon unter <a href="tel:+41 44 552 35 00">+41 44 552 35 00</a> oder per E-Mail
      unter
      <a href="mailto:mandate@life-gate.ch">mandate&#64;life-gate.ch</a>.
      Wir sind Montag bis Freitag von 8 Uhr bis 18 Uhr für Sie erreichbar.
    </div>
  </div>

  <div class="row pt-sm pb-sm">
    <div class="col col-12 pb-sm">
      <a href="impressum" [routerLink]="['impressum']" style="text-decoration: none; margin-right: 12px;">Impressum</a>
      <a href="datenschutz" [routerLink]="['datenschutz']"
         style="text-decoration: none; margin-right: 12px;">Datenschutz</a>
      <a href="disclaimer" [routerLink]="['disclaimer']"
         style="text-decoration: none; margin-right: 12px;">Disclaimer</a>
      <a href="agb" [routerLink]="['agb']" style="text-decoration: none;">AGB's</a>
    </div>
  </div>
</div>
