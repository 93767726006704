import {AfterViewInit, Component} from '@angular/core';
import {NgClass, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelDescription, MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from "@angular/material/expansion";
import {RouterLink} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {ContactDialogComponent} from "../../shared/contact-dialog/contact-dialog.component";
import Swiper from "swiper";
import {ViewportObserverDirective} from "../../shared/directives/viewport-observer.directive";
import {AnimationDirective} from "../../shared/directives/animation.directive";
import {FadeInDirective} from "../../shared/directives/fade-in.directive";
import {MatIcon} from "@angular/material/icon";


@Component({
    selector: 'app-campagne',
    templateUrl: './campagne.component.html',
    styleUrls: ['./campagne.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    MatButton,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelHeader,
    RouterLink,
    AnimationDirective,
    FadeInDirective,
    MatIcon,
  ]
})
export class CampagneComponent implements AfterViewInit{
  contentData = [
    {
      image: "assets/images/images/placeholder.jpg",
      title: "Einfache Eingabe<br>der Kundendaten",
      description:
        "Die benutzerfreundliche Anwendung von maklermandat.ch ermöglicht eine schnelle und einfache Erfassung von Kundendaten und bestehenden Policen.",
    },
    {
      image: "assets/images/images/placeholder2.jpg",
      title: "Digitale<br>Vertragsunterzeichnung",
      description:
        "Die Verträge werden digital unterzeichnet und automatisch übermittelt, was den administrativen Aufwand erheblich reduziert.",
    },
    {
      image: "assets/images/images/placeholder3.jpg",
      title: "Passives Einkommen durch<br>wiederkehrende Courtagen",
      description:
        "Anschliessend profitieren Sie von regelmässigen Provisionen/Courtagen und bauen so ein stabiles und nachhaltiges passiv Einkommen auf.",
    },
  ];

  contentBenefitsData = [
    {
      title: "Langfristige Kundenbindung",
      description:
        "Ein Maklermandat schafft eine Grundlage für langfristige Beziehungen. Anstatt punktuell Neuverträge abzuschliessen, bauen Sie eine dauerhafte Zusammenarbeit mit dem Kunden auf, welche die regelmässige Prüfung und Bearbeitung seines Versicherungsportfolios umfasst. ",
    },
    {
      title: "Weniger Verkaufsdruck",
      description:
        "Da Sie für die Verwaltung und Betreuung der laufenden Versicherungspolicen Ihrer Kundschaft Provisionen bzw. Courtagen erhalten, besteht weniger Druck neue Versicherungsverträge abzuschliessen, was Ihnen erlaubt, sich stärker auf die Qualität der Beratung und die langfristige Betreuung Ihrer Kunden zu konzentrieren. Dies verbessert nicht nur die Kundenbindung, sondern schafft auch mehr Möglichkeiten für Cross-Selling und Up-Selling.",
    },
    {
      title: "Fixe Provisionen/Courtagen auf bestehende Verträge",
      description:
        "Die Provision bzw. Courtage  basiert auf den bestehenden Versicherungsverträgen in Ihrem Kundenportfolio welche via maklermandat.ch mandatiert wurden, unabhängig davon, ob neue Vertragsabschlüsse getätigt werden oder nicht. Dadurch entsteht ein regelmässiger Einkommensfluss, der nicht ausschliesslich an den Verkauf neuer Policen gebunden ist. ",
    },
  ];

  contentFunctionData = [
    {
      image: "assets/images/images/placeholder.jpg",
      title: "Nahtlose Integration<br>in die Kundenbetreuung",
      description:
        "Nutzen Sie zukünftige Beratungen, um dem Kunden die Vorteile eines Maklermandats zu erklären. So können Sie künftig alle Versicherungsangelegenheiten zentral und umfassend betreuen.",
    },
    {
      image: "assets/images/images/placeholder2.jpg",
      title: "Datenaufnahme und<br>Vertragsübertragung",
      description:
        "Entscheidet sich der Kunde für das Maklermandat, erfassen Sie die relevanten Daten und lassen die Mandatserklärung digital unterzeichnen. Im Hintergrund wird die Vertragsverwaltung übertragen, und die Versicherungsgesellschaften wird informiert, dass sich die Ansprechperson sprich der Broker ändert.",
    },
    {
      image: "assets/images/images/placeholder3.jpg",
      title: "Betreuung des<br>Versicherungsportfolios",
      description:
        "Als Broker verwalten Sie alle Verträge im Namen des Kunden, kümmern sich um Anpassungen, Erneuerungen und die Kommunikation mit Versicherern. Mit einer umfassenden Übersicht können Sie Deckungslücken erkennen und, wenn gewünscht, bessere Angebote einholen, um die optimale Absicherung sicherzustellen.",
    },
  ];

  contentBenefitsMandatData = [
    {
      title: "Einfache digitale Abwicklung",
      description:
        "Effiziente und benutzerfreundliche Plattform für die zentrale Abwicklung der Mandatserklärung.",
    },
    {
      title: "Flexibles Hinzufügen von Kundenberatern",
      description:
        "Jeder Berater erhält einen eigenen Zugang zur Plattform.",
    },
    {
      title: "Individuelle Branding-Optionen ",
      description:
        "Integration Ihres Logos und Anpassung der Farben für ein professionelles, personalisiertes  Erscheinungsbild.",
    },
    {
      title: "Zugang zu über 50 Versicherungsgesellschaften",
      description:
        "Mit unserem Portfolio erhalten Sie Zugang zu über 50 Versicherungsgesellschaften für eine optimale Beratung Ihrer Kunden.",
    },
  ];

  constructor(public dialog: MatDialog) {}


  activeImage = this.contentData[0].image;
  activeIndex = 0;
  swiper!: Swiper;
  panelOpenState!: boolean;

  resizeListener!: () => void;

  ngAfterViewInit() {
    this.setupSwiper();
  }

  setActiveImage(image: string, index: number): void {
    this.activeImage = image;
    this.activeIndex = index;
  }

  openDialog() {
    const dialogRef = this.dialog.open(ContactDialogComponent, {
      width: '400px',
      data: {}, // Falls du Daten übergeben willst
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('Dialog geschlossen mit Daten:', result);
      } else {
        console.log('Dialog abgebrochen');
      }
    });
  }

  setupSwiper() {
    this.swiper = new Swiper('.swiper', {
      slidesPerView: 1.2,
      spaceBetween: 24,
      navigation: true,
      slidesOffsetBefore: 24,
      slidesOffsetAfter: 24,

    });
  }

  onResize() {
    const globalPadding = this.getGlobalPadding();

    // Ensure breakpoints are defined
    if (this.swiper.params.breakpoints) {
      this.swiper.params.slidesOffsetBefore = globalPadding;
      this.swiper.params.slidesOffsetAfter = globalPadding;
      this.swiper.update();
    }
  }

  getGlobalPadding(): number {
    const globalPadding = getComputedStyle(document.documentElement)
      .getPropertyValue('--global-padding')
      .trim()
    return parseInt(globalPadding);
  }

}
